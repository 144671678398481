<template>
    <div class="d-flex justify-content-center align-items-center" style="height: 100vh;">
      <div>
        <p class="text-center">Finalizando autenticação com Google...</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {

    mounted() {       
      const status = this.$route.query.status
      if (window.opener) {
        window.opener.postMessage(
          status === "success" ? "google_auth_success" : "google_auth_error",
          "http://localhost:8080"
        );
        window.close();
      }
    }

  };
  </script>
  
  <style scoped>
  p {
    font-size: 1.2rem;
  }
  </style>